@import "./sass/mixins";

$header-height: 120px;

#app {
  width: 100%;

  header {
    display: flex;
    flex-direction: row;
    font-family: "Hachi Maru Pop";
    background-color: #f9e3ef;
    height: $header-height;
    width: 100%;

    @include bp-small {
      flex-direction: column;
      height: unset;
      text-align: center;

    }

    img {
      height: 100%;

      @include bp-small {
        width: 100%;
        height: unset;
      }
    }

    h1 {      
      background-color: #f9e3ef;
      padding-left: 1rem;
      font-size: 3rem;
      font-weight: bold;
      height: $header-height;
      line-height: $header-height;
      margin: 0;      

      @include bp-small {        
        height: unset;
      }
    }    
  }

  #content {    
    margin: 4%;
    display:flex;
    flex-direction: column;
    width: 92%;    
    font-family: "Open Sans", sans-serif;

    h2 {
      font-size: 2rem;

      @include bp-small {
        text-align: center;
      }
    }    

    #photo-wrapper {      
      display:flex;
      flex-direction: row;     
      justify-content: space-between;             
      width: 100%;

      @include bp-small {
        flex-direction: column;
        width: 100%;

        .photo-wrapper {
          margin: 1em auto;        
        }
      }

      .photo {
        width: 22%;
        height: 350px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: center;

        @include bp-small {
          width: 100%;
          margin-bottom: 1rem;
        }
      }            
    }    
  }
}